import { AppBar, Slide, Toolbar, useScrollTrigger } from "@material-ui/core"
import { Link } from "gatsby"
import React from "react"
import { appBar, logo, logoWrapper } from "./header.module.sass"

const HideOnScroll = (props) => {
  const trigger = useScrollTrigger()
  const { children } = props

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const Header = () => (
  <HideOnScroll>
    <AppBar className={appBar}>
      <Toolbar variant="dense">
        <div className={logoWrapper}>
          <Link to="/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 880 80"
              className={logo}
            >
              <desc>キクナントカドットコム</desc>
              <path
                d="M73.2 18.32l-1.84 13.36H46.4L46 46.64h23.2l-1.36 9.76H45.76l-.48 17.36h-6.72L35.84 56.4H11.76l-1.84-9.76H34.4l-2.32-14.96H7.68l-2.4-13.36H30l-1.92-12.4h19.04l-.32 12.4h26.4zm76.72-8.08L129.2 73.92h-6.72l7.84-49.6h-22.96l-5.76 24.96H92l2.72-39.04h55.2zm83.04 15.68l-1.84 12.96h-24.8l-6.4 34.32h-7.28l.4-34.32h-23.28l-1.6-12.96h25.04l.24-18.88h18.88l-3.6 18.88h24.24zm47.2 4.56l-30.56-.08-2.08-14.64h32.56l.08 14.72zm32.8-17.04l-10.72 59.2h-47.12l-1.04-8.16h41.28l2.32-51.04h15.28zm73.92 30.08l-1.6 9.76-36.64-8.72.16 28.48h-8L330.64 8.88h17.76l.16 20.8 38.32 13.84zM470.4 17.2l-10.16 55.28h-6.4l.56-41.68h-20.16l-3.28 41.52h-6.48l-4.32-41.52h-11.6l-1.92-13.6h12.08l-.96-9.76h18.32l-.8 9.76h35.12zm79.92-6.48l-1.44 9.84h-6.08l1.12-9.84h6.4zm-8.4 0l-1.44 9.84h-5.6l.56-9.84h6.48zm5.04 32.88l-1.6 9.84-36.64-8.8.16 28.56h-8L490.72 9.04h17.76l.16 20.72 38.32 13.84zm55.92-8.88L600.8 54.4h-7.28l-.64-19.68h10zm19.12-.48l-7.2 39.52h-29.52l-.72-5.36h25.76l1.76-34.16H622zM590.48 54.4h-7.04l-3.84-19.76h10.08l.8 19.76zm116.4-10.88l-1.6 9.76-36.64-8.72.16 28.48h-8L650.64 8.88h17.76l.16 20.8 38.32 13.84zm84.96-29.36l-9.36 58.08h-45.04l-1.04-8.8h38.96l.4-35.12h-43.92L730 14.16h61.84zm77.68 49.52l-.88 8.4L812.8 72l-1.28-8.72 13.2-53.2h18l-20.88 53.44h38l-8.8-24.96H864l5.52 25.12z"
                fill="#333"
                fill-rule="nonzero"
              />
            </svg>
          </Link>
        </div>
      </Toolbar>
    </AppBar>
  </HideOnScroll>
)

export default Header
