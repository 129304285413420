import "modern-css-reset"
import "prismjs/plugins/line-numbers/prism-line-numbers.css"
import "prismjs/themes/prism-tomorrow.css"
import React from "react"
import Header from "../components/header"
import Seo from "../components/seo"
import "./global.module.sass"
import { wrapper } from "./layout.module.sass"

const Layout = ({ children }) => (
  <>
    <Seo />
    <Header />
    <div className={wrapper}>{children}</div>
  </>
)

export default Layout
