import React from "react"
import Helmet from "react-helmet"

const Seo = ({ description, meta = [], keywords, title }) => (
  <Helmet
    htmlAttributes={{
      lang: "ja",
    }}
    defaultTitle="キクナントカドットコム"
    title={title}
    titleTemplate="%s | キクナントカドットコム"
    meta={[
      {
        name: `description`,
        content: description || "キクナントカの日々のアウトプット",
      },
      {
        name: `keywords`,
        content:
          keywords || "ブログ, 個人開発, JS, Ruby, GatsbyJS, ボードゲーム制作",
      },
      {
        property: `og:title`,
        content: title || "キクナントカドットコム",
      },
      {
        property: `og:description`,
        content: description || "キクナントカの日々のアウトプット",
      },
      {
        property: `og:site_name`,
        content: `キクナントカドットコム`,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        property: "og:image",
        content: "https://kikunantoka.com/assets/images/ogp.png",
      },
      {
        name: `twitter:card`,
        content: `summary_large_image`,
      },
      {
        name: `twitter:site`,
        content: `@kikunantoka`,
      },
      {
        name: `twitter:creator`,
        content: `@kikunantoka`,
      },
    ].concat(meta)}
  />
)

export default Seo
